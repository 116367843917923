<template>
    <div>

    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "GoodsDetail",
    data() {
        return {
            goodsId: null,
            loading: false,
        }
    },
    mounted() {
        this.goodsId = this.$route.params.id
        // this.fetchGoods()
    },
    methods: {
        fetchGoods() {
            this.loading = true

            api.get('/ast-shop/categories', {id: this.goodsId}, data => {

                if (!data.status) {
                    this.$message.error(data.msg)
                    this.$router.back()
                    return;
                }

                console.log(data);

            });
        }
    }
}
</script>

<style scoped lang="less">

</style>